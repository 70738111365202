
<section class="page-main">
  <div id="labels"></div>
  <div id="moon"></div>
  <div id="container"></div>
  <div id="markerLabel" class="hidden" ref="markerLabel">
    <button id="closeButton" ref="clostBtn">X</button>
    <div class="text" id="idNum" ref="idNumRef">{{info.id}}</div>
    <div class="text" id="type" ref="typeRef">{{info.type}}</div>
    <div class="text" id="date" ref="dateRef">{{info.date}}</div>
    <div class="text" id="magnitude" ref="magnitudeRef">{{info.mag}}</div>
    <div class="text" id="depth" ref="depthRef">{{info.depth}}</div>
    <div class="text" id="coordinates" ref="coordinatesRef">{{info.coord}}</div>
  </div>
  <div class="eventMode" v-if="state.mode == 1">
    <div class="info" v-if=" state.startTime != 0 && state.type != ''">
      <div v-if="state.startTime!=''" class="startTime">StartTime:{{state.startTime}}</div>
      <div v-if="state.duration!=''" class="duration">Duration:{{state.duration}}</div>
      <div v-if="state.type!=''" class="type">Type:{{state.type}}</div>
    </div>
  </div>
  <!-- timeline -->
  <div class="switch-block">
    <ul class="select-switch-block">
      <li :class="[{'active': state.mode === 0}]" class="first" @click="onChange(0)" >Moonquake Epicenters</li>
      <li :class="[{'active': state.mode === 1}]" class="last"  @click="onChange(1)">Apollo Seismometers</li>
    </ul>
  </div>
  <div v-if="state.mode == 0" class="timeblock">
    <ui-time-slider :start="dateStart" :end="dateEnd" @updateTime="updateDate($event)" ></ui-time-slider>
  </div>
  <div v-if="state.mode == 1" class="timeblock">
    <ui-timeline v-if="timeEventDatas" @onClick="add_station($event)" :series="timeEventDatas"></ui-timeline>
  </div>
  
</section>

