
<div class="ui-time-slider">
  <div class="info-block">
    <p class="info-text">{{timeDisplay.start}} </p>
    <div class="info-text"> ~ </div>
    <p class="info-text">{{timeDisplay.end}}</p>
  </div>
  <div  class="sliders-block" >
  <Slider v-model="value" :tooltips="false"  @update="onUpdateSlider" :format="datesFormat" :min="startTime" :max="endTime" />
  <ul class="label-block">
    <li  v-for="i in years">{{i}} </li>
  </ul>
  </div>
  
</div>

